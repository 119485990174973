<template>
  <div>
    <el-row style="width: 100%">
      <el-header style="margin-top:20px">生成鞋印批次号</el-header>
      <el-form ref="form" :model="form" :rules="rules" label-width="70px" style="display: flex;">
        <el-col :span="6">
          <el-form-item label="STYLE" :class="$i18n.locale" prop="style">
            <el-select v-model="form.style" value-key="id" clearable filterable>
              <el-option
                v-for="item in styleOptions"
                :key="item.id"
                :label="item.styleName"
                :value="item.styleName"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日期" :class="$i18n.locale" prop="date">
            <el-date-picker
              v-model="form.date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="供应商" :class="$i18n.locale" prop="vendorId">
            <el-select v-model="form.vendorId" filterable clearable :placeholder="$t('page.selectPlaceholder')">
              <el-option
                v-for="item in vendorOptions"
                :key="item.id"
                :label="item.vendorName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" class="ml-3" @click="confirmd">确定</el-button>
          <el-button type="primary" class="ml-3" @click="handleEmptyForm">清空</el-button>
        </el-col>
      </el-form>
    </el-row>
    <el-table :data="tableData" border stripe max-height="550px">
      <el-table-column label="STYLE" prop="style" align="center" />
      <el-table-column label="供应商" prop="vendorName" align="center" />
      <el-table-column label="批次号" prop="shoeBatchNo" align="center" />
    </el-table>
    <div>
      <el-row style="width: 100%">
        <el-header style="margin-top:20px">分解鞋印批次号</el-header>
        <el-form ref="Shoeform" :model="Shoeform" label-width="60px" style="display: flex;">
          <el-col :span="5">
            <el-form-item label="批次号" :class="$i18n.locale">
              <el-input v-model="Shoeform.shoeBatchNo" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-button type="primary" class="ml-3" @click="_splitShoeBatchNo">确定</el-button>
            <el-button type="primary" class="ml-3" @click="handleEmpty">清空</el-button>
          </el-col>
        </el-form>
      </el-row>
      <el-table :data="SplitTableData" border stripe max-height="550px">
        <el-table-column label="STYLE" prop="style" align="center" />
        <el-table-column label="供应商" prop="vendor" align="center" />
        <el-table-column label="时间" prop="date" align="center" />
      </el-table>
    </div>

  </div>
</template>

<script>
import { queryVendorList, splitShoeBatchNo, generateShoeBatchNo, queryStyleList } from '@/api/scm-api'
export default {
  data() {
    return {
      styleOptions: [],
      form: {
        style: '',
        date: '',
        vendorId: ''
      },
      Shoeform: {
        shoeBatchNo: ''
      },
      addfnskuUpc: '',
      tableData: [],
      SplitTableData: [],
      vendorOptions: [],
      rules: {
        style: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        date: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        vendorId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      }
    }
  },
  mounted() {
    this._queryStyleList()
    this._queryVendorList()
  },
  methods: {
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    confirmd() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          this._generateShoeBatchNo(this.form)
        }
      })
    },
    async _generateShoeBatchNo(formData) {
      const { datas, code, msg } = await generateShoeBatchNo(formData)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.form = {}
        this.tableData.push(datas)
      }
    },

    async _splitShoeBatchNo() {
      const { datas, code, msg } = await splitShoeBatchNo(this.Shoeform)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.Shoeform = {}
        this.SplitTableData.push(datas)
      }
    },
    handleEmptyForm() {
      this.form = {}
    },
    handleEmpty() {
      this.Shoeform = {}
    }
  }
}
</script>
